<template>
  <div class="splash-header" :style="style" ref="header">
    <div class="container is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
      <div>
        <div class="columns is-multiline">
          <div class="column is-full">
            <h2 class="title is-1 has-text-left m-0 has-text-white" :class="{ 'slide-in-left': shouldShow, 'invisible': !shouldShow }">
              {{ $at(audience, 'headline') }}
            </h2>
          </div>
          <div class="column is-full">
            <h2 class="title is-1 has-text-right m-0 has-text-white" :class="{ 'slide-in-right': shouldShow, 'invisible': !shouldShow }">{{ textToShow }}</h2>
          </div>
        </div>
        <div class="column is-full">
            <h2 class="title is-1 has-text-left m-0 has-text-white" :class="{ 'slide-in-left-delay': shouldShow, 'invisible': !shouldShow }">
              {{ "60 " + $t('minutes') }}
            </h2>
          </div>
      </div>
      <div class="p-5" :class="{ 'fade-in': shouldShow, 'invisible': !shouldShow }">
        <p>{{ $t('advice-block-information') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from '../../store'

export default {
  name: 'CapacityBlock',
  props: {
    audience: Object
  },
  data(){
    return{
      shouldShow: false
    }
  },
  computed: {
    capacity() {
      return store.getVenueCapacity()
    },
    language() {
      return store.language
    },
    textToShow() {
      const { smallestGroupCount, largestGroupCount } = this.smallestAndBiggestGroups();

      if (smallestGroupCount == 2 && largestGroupCount > 10) {
        return this.$t('advice-block-from', { playercount: "2-" + this.capacity['abs_max'] });
      }
      else if (smallestGroupCount == 2) {
        return this.$t('advice-block-from', { playercount: "2" });
      }
      else if (largestGroupCount > 10) {
        return this.$t('advice-block-to', { playercount: this.capacity['abs_max'] });
      }
      else {
        return "";
      }
    },
    style() {
      let img = '/apuz/venue/static/images/' + this.audience.landingBlocks.capacity.coverPicture
      return 'background-image: url("' + img + '");'
    }
  },
  methods: {
    computeVisibility(){
      return true
    },
    smallestAndBiggestGroups() {
      let smallestGroupCount = Infinity;
      let largestGroupCount = 0;

      this.audience['group-templates'].forEach(template => {
        let sum = 0;
        Object.values(template.participants).forEach(value => sum += value);
        if (sum < smallestGroupCount) {
          smallestGroupCount = sum;
        }
        if (sum > largestGroupCount) {
          largestGroupCount = sum;
        }
      });

      return {
        smallestGroupCount,
        largestGroupCount
      };
    },
    isVisibleOnScreen() {
      const element = this.$refs.header;
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const isVisible = elementRect.top < window.innerHeight && elementRect.bottom >= 0;

        if (isVisible) {
          this.shouldShow = true
        } else {
          this.shouldShow = false
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.isVisibleOnScreen)
    this.isVisibleOnScreen()
  },
  unmounted(){
    window.removeEventListener('scroll', this.isVisibleOnScreen)
  }

}

</script>

<style scoped>
.invisible{
  opacity: 0;
}
.splash-header {
  background-size: cover;
  padding-top: 20vh;
  padding-bottom: 20vh;
  background-position: center;
  box-shadow: inset -1px -5vh 50px 10px rgba(0, 0, 0, 1);
  margin-bottom: -1rem;
}

.slide-in-left {
  animation: slideInLeft 1s forwards;
  opacity: 0;
}

.slide-in-left-delay {
  animation: slideInLeft 1s forwards;
  animation-delay: 2s;
  opacity: 0;
}

.slide-in-right {
  animation: slideInRight 1s forwards;
  animation-delay: 1s;
  opacity: 0;
}

.fade-in {
  animation: fadeIn 1s forwards;
  animation-delay: 3s;
  opacity: 0;
}

.container {
  width: 50rem;
  height: 40vh;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    height: auto;
    padding: 1rem;
  }

  .title.is-1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .columns {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }

  .has-text-left {
    text-align: center !important;
  }

  .has-text-right {
    text-align: center !important;
  }

  .fade-in {
    padding: 0.5rem;
  }
}
</style>
