<template>
  <section class="hero is-large booking-steps is-medium-section has-text-centered">
    <div class="container is-max-desktop">
      <h3 class="title has-text-info is-size-3 pt-5 has-text-centered">{{ $t('booking-steps-title') }}</h3>
      <p class="py-5 subtitle">{{ $t('booking-steps-intro') }}</p>

      <div class="fixed-grid">
        <div class="grid">
          <div v-for="step in steps" class="cell" :key="step.name" @click="toggleStepVisibility(step.name)">
          <i class="fa is-color-info" :class="'fa-' + $t('booking-steps-' + step.name + '-icon')"></i>
          <p class="title is-5 p-2">{{ $t('booking-steps-' + step.name + '-title') }}</p>
          <p class="subtitle">{{ $t('booking-steps-' + step.name + '-text') }}</p>
        </div>
        </div>
      </div>
    </div>

    <div style="clear: both; height: 150px;"></div>

  </section>
</template>
<script>
export default {
  name: 'ConceptBlock',
  data() {
    return {
      steps: [
        { name: "first", visible: false },
        { name: "second", visible: false },
        { name: "third", visible: false },
        { name: "fourth", visible: false },
      ],
    };
  },
  methods: {
    computeVisibility(){
      return true
    },
    toggleStepVisibility(stepName) {
      const step = this.steps.find(s => s.name === stepName);
      if (step) {
        step.visible = !step.visible;
      }
    },
  },
}
</script>
<style scoped>
.booking-steps {
  margin-top: 0;
}

.booking-steps .columns:before {
  border-bottom: 2px dotted rgba(255, 255, 255, 0.2);
  width: 78%;
  height: 0px;
  position: absolute;
  top: 95px;
  content: '';
  left: 11%;
}

.booking-steps .fa {
  font-size: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}
</style>
