<template>
  <div class="container mt-5 p-2 is-flex is-flex-direction-column">
    <h2 class="title has-text-centered is-1 is-color-info pb-2">{{ $t("opening-schedule-title")}}</h2>
    <div class="card">
      <div class="columns is-gapless">
        <div class="column">
          <div class="is-flex is-align-items-baseline p-5">
            <span class="icon is-large is-clickable p-2" @click="changeMonth(-1)">
              <i class="fas fa-arrow-alt-circle-left fa-2x has-text-info"></i>
            </span>
            <div class="title is-flex-grow-1 has-text-centered has-text-info">{{ $t(currentMonthName.toLowerCase())
              }}</div>
            <span class="icon is-large is-clickable p-2" @click="changeMonth(1)">
              <i class="fas fa-arrow-alt-circle-right fa-2x has-text-info"></i>
            </span>
          </div>
          <div class="table-container">
            <table class="table is-fullwidth has-text-centered">
              <thead>
                <tr>
                  <th class="p-0" v-for="weekDay in MTWTFSS.split('')" :key="weekDay"><p class="subtitle">{{ weekDay }}</p></th>
                </tr>
              </thead>
              <tbody>
                <tr class="p-0" v-for="week in weeks" :key="week[0]">
                  <td class="p-0" v-for="day in week" :key="day ? day : 'empty'"
                    :class="{ 'today': day && day.isSame(selectedDate, 'day'), 'selected': day && day.isSame(selectedDate, 'day') }">
                    <button v-if="day && day.isBefore(currentDate) && !day.isSame(currentDate)" disabled class="button is-small is-fullwidth">{{
                      day.format('D') }}</button>
                    <button v-else-if="day" @click="setDay(day)" class="button is-small is-fullwidth"
                      :class="{ 'is-primary': day.isSame(selectedDate, 'day') }">
                      {{ day.format('D') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column">
            <div v-if="loading" class="content is-flex is-align-items-center is-justify-content-center ">
              <div class="fa-3x">
                <i class="fas fa-circle-notch fa-spin"></i>
              </div>
            </div>
            <div v-else-if="filteredDayplan.length != 0 && !closed" class="content">
              <div v-for="(plan, index) in filteredDayplan" :key="index" class="is-flex is-align-items-baseline	">
                <i class="far fa-clock is-medium icon"></i>
                <p class="subtitle has-text-right" style="width: max-content;">{{ plan.time }}</p>
                <p v-if="plan.type == 'availability'" class="pl-2">{{ $t('dayplan-availability-' +
                  plan.availability) }}</p>
                <p v-else class="pl-2">{{ $t('dayplan-' + plan.type) }}</p>
                <p class="pl-2" v-html="availabilityEmoji(plan.availability)"></p>
              </div>
            </div>
            <div v-else-if="closed">
              <h4 v-if="selectedDateIsToday" class="title has-text-warning">{{ $t('start-schedule-closed-today') }}</h4>
              <h4 v-else class="title has-text-warning">{{ $t('start-schedule-closed-date', {date: dateToTranslatedText(selectedDate)}) }}</h4>
            </div>
            <div v-else-if="filteredDayplan.length == 0">
              <h4 class="title has-text-warning pb-4">{{ $t('booking-availability-unavailable-title') }}</h4>
              <p class="subtitle is-size-6">{{ $t('booking-availability-unavailable-text') }}</p>
            </div>
          </div>
          <div class="column">
            <router-link :to="bookingLink()" class="button is-large is-success mb-2 is-fullwidth">{{
              $t('book-here') }}</router-link>
            <p>{{ $t('opening-hours-block-schedule-changes') }}</p>
          </div>
          <div class="is-hidden-desktop column is-flex is-justify-content-space-between">
            <div v-if="currentDaySchedule">
              <div class="content p-2">
                <h6 class="is-subtitle">{{ $t('opening-hours-block-on-site-header') }}</h6>
                <p>{{ $t('opening-hours-block-on-site-text') }}</p>

                <h6 class="is-subtitle">{{ $t('opening-hours-block-big-groups-header') }}</h6>
                <p>{{ $t('opening-hours-block-big-groups-text') }}</p>

                <h6 v-if="currentDaySchedule" class="is-subtitle">{{ $t('opening-hours-block-pick-up-header') }}
                </h6>
                <p v-if="currentDaySchedule && currentDaySchedule.staffPresence.length > 0">{{
                  $t('opening-hours-block-pick-up-text', {
                    date: selectedDateIsToday ?
                      selectedTodayTranslated : dateToTranslatedText(this.selectedDate), hours:
                      combinedTime(currentDaySchedule.staffPresence)
                  }) }}</p>
                <p v-else>{{ $t('opening-hours-block-pick-up-no-guests-text', {
                  date: selectedDateIsToday ?
                    selectedTodayTranslated : dateToTranslatedText(this.selectedDate)
                }) }}</p>

                <h6 class="is-subtitle">{{ $t('opening-hours-block-book-website-header') }}</h6>
                <p>{{ $t('opening-hours-block-book-website-text') }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- DESKTOP -->
        <div class="is-hidden-touch column has-background">
          <div class="content p-5">
            <h6 class="is-subtitle">{{ $t('opening-hours-block-on-site-header') }}</h6>
            <p>{{ $t('opening-hours-block-on-site-text') }}</p>

            <h6 class="is-subtitle">{{ $t('opening-hours-block-big-groups-header') }}</h6>
            <p>{{ $t('opening-hours-block-big-groups-text') }}</p>


            <h6 v-if="currentDaySchedule" class="is-subtitle">{{ $t('opening-hours-block-pick-up-header') }}</h6>
            <p v-if="currentDaySchedule && currentDaySchedule.staffPresence.length > 0">{{
              $t('opening-hours-block-pick-up-text', {
                date: selectedDateIsToday ?
                  selectedTodayTranslated : dateToTranslatedText(this.selectedDate), hours:
                  combinedTime(currentDaySchedule.staffPresence)
              }) }}</p>
            <p v-else>{{ $t('opening-hours-block-pick-up-no-guests-text', {
              date: selectedDateIsToday ?
                selectedTodayTranslated : dateToTranslatedText(this.selectedDate)
            }) }}</p>


            <h6 class="is-subtitle">{{ $t('opening-hours-block-book-website-header') }}</h6>
            <p>{{ $t('opening-hours-block-book-website-text') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(customParseFormat);
dayjs.extend(updateLocale)

export default {
  name: 'OpeningScheduleBlock',
  props: {
    audience: Object
  },
  data() {
    return {
      currentDaySchedule: null,
      selectedDate: dayjs(),
      loading: true,
      currentDate: dayjs(),
      selectedDay: null
    }
  },
  computed: {
    getDaysOfCurrentMonth() {
      const daysInMonth = this.curDayjs.daysInMonth();
      const daysArray = [];

      for (let day = 1; day <= daysInMonth; day++) {
        daysArray.push(this.curDayjs.date(day))
      }

      return daysArray;
    },
    closed() {
      return this.currentDaySchedule && this.currentDaySchedule.schedule.closed
    },
    currentMonthName() {
      return this.curDayjs.format('MMMM')
    },

    curDayjs() {
      return dayjs(this.selectedDate)
    },
    canStretch() {
      const canStretch = this.audience["group-templates"].some(group => group.canStretch)
      return canStretch
    },
    filteredDayplan() {
      if (!this.currentDaySchedule || !this.currentDaySchedule.dayplan) {
        return []
      }
      const currentSchedule = this.currentDaySchedule.dayplan.filter(plan => {
        plan.time = plan.time.replace(/:.*/, '')
        if (!this.canStretch && (plan.type === 'stretched-opening' || plan.type === 'stretched-closing')) {
          return false
        }
        return true
      })

      return this.combineAvailability(currentSchedule)
    },
    selectedTodayTranslated() {
      return this.$t('today') + " " + this.dateToTranslatedText(this.selectedDate)
    },
    selectedDateIsToday() {
      return this.selectedDate.isSame(this.currentDate, 'day')
    },
    weeks() {
      const days = this.getDaysOfCurrentMonth
      const weeks = []
      let week = []

      const firstDayOfWeek = (days[0].day() + 6) % 7
      for (let i = 0; i < firstDayOfWeek; i++) {
        week.push(null)
      }

      days.forEach(day => {
        if (week.length < 7) {
          week.push(day)
        }
        else {
          weeks.push(week)
          week = [day]
        }
      })

      while (week.length < 7) {
        week.push(null)
      }

      if (week.length) {
        weeks.push(week)
      }

      return weeks
    },
    MTWTFSS() {
      const daynames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      return daynames.map(day => this.$t(day)[0]).join('')
    }
  },
  methods: {
    computeVisibility() {
      return true
    },
    changeMonth(index) {
      this.selectedDate = dayjs(this.selectedDate).add(index, 'month')

      this.getDaySummary()
    },
    setDay(date) {
      this.selectedDate = date

      this.getDaySummary()
    },
    getDaySummary() {
      this.loading = true
      axios.get('/booking/summary/' + this.curDayjs.format('YYYY-MM-DD') + '.json').then((response) => {
        this.currentDaySchedule = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    combinedTime(times) {
      const toMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      const combined = times.reduce((acc, [start, end], i) => {
        if (i === 0 || toMinutes(start) - toMinutes(acc[acc.length - 1][1]) >= 60) {
          acc.push([start, end]);
        } else {
          acc[acc.length - 1][1] = end;
        }
        return acc;
      }, []);

      return combined.map(([start, end]) => `${start}-${end}`).join(', ');
    },
    combineAvailability(dayplan) {
      let startDay = null;
      let combinedDayplan = [];

      dayplan.forEach((day, index) => {
        if (day.type === 'availability') {
          if (startDay === null) {
            startDay = { ...day };
          } else if (day.availability !== startDay.availability) {
            if (startDay.time === dayplan[index - 1].time) {
              combinedDayplan.push({
                type: 'availability',
                availability: startDay.availability,
                time: "ca. " + startDay.time
              });
            } else {
              combinedDayplan.push({
                type: 'availability',
                availability: startDay.availability,
                time: startDay.time + ' - ' + dayplan[index - 1].time
              });
            }
            startDay = { ...day };
          }
        } else {
          if (startDay !== null) {
            if (startDay.time === dayplan[index - 1].time) {
              combinedDayplan.push({
                type: 'availability',
                availability: startDay.availability,
                time: "ca. " + startDay.time
              });
            } else {
              combinedDayplan.push({
                type: 'availability',
                availability: startDay.availability,
                time: startDay.time + ' - ' + dayplan[index - 1].time
              });
            }
            startDay = null;
          }
          combinedDayplan.push({ ...day, time: "ca. " + day.time });
        }
      });

      if (startDay !== null) {
        if (startDay.time === dayplan[dayplan.length - 1].time) {
          combinedDayplan.push({
            type: 'availability',
            availability: startDay.availability,
            time: "ca. " + startDay.time
          });
        } else {
          combinedDayplan.push({
            type: 'availability',
            availability: startDay.availability,
            time: startDay.time + ' - ' + dayplan[dayplan.length - 1].time
          });
        }
      }

      return combinedDayplan;
    },
    availabilityEmoji(availability) {
      if (availability == 'good') {
        return '&#x270C'
      }
    },
  },
  mounted() {
    this.getDaySummary()
  }
}
</script>
