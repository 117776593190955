<template>
  <div class="container mt-5 p-2">
    <div class="fixed-grid" :class="{ 'has-1-cols': !hasTwoColumns, 'has-2-cols': hasTwoColumns }"> 
       <div v-for="(entry, index) in entries" :key="index" class="cell mb-2">
        <div @click="toggle(index)" class="box is-clickable">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title is-4 m-0">{{ $at(entry, 'q') }}</p>
            <span class="icon">
              <i class="fas fa-caret-down" :class="{ 'rotate-180': isOpen(index) }"></i>
            </span>
          </div>
          <p v-show="isOpen(index)" class="pt-2">{{ $at(entry, 'a') }}</p>
          <p v-if="isPartiallyOpen(index)" class="pt-2">{{ partialContent($at(entry, 'a')) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import all_faqs from '/storage/tmp/faqs.json'

export default {
  props: ['faqs'],
  name: 'FAQBlock',
  data() {
    return {
      openIndexes: [],
      partialOpenIndexes: [],
      localFaqs: []
    }
  },
  computed: {
    entries() {
      return this.localFaqs.map(f => all_faqs[f])
    },
    hasTwoColumns(){
      return (this.isDesktop() && this.entries.length > 1)
    }
  },
  methods: {
    calculatePosition(){
      this.entries.slice(0, 2).forEach((faq, index) => {
        const entry = this.entries[index]
        const content = this.$at(entry, 'a')
        if (content.length > 100) {
          this.partialOpenIndexes.push(index)
        } 
        else {
          this.openIndexes.push(index)
        }
      })
    },
    removeEmptyFaq(){
      this.entries.forEach((faq,index) => {
        const entry = this.entries[index]
        const content = this.$at(entry, 'a')
        if (content === '') {
          console.error('Empty FAQ found:', entry)
          this.localFaqs.splice(index, 1)
        }
      })
    },
    toggle(index) {
      if (this.partialOpenIndexes.indexOf(index) !== -1) {
        const idx = this.partialOpenIndexes.indexOf(index);
        this.partialOpenIndexes.splice(idx, 1);
      }
      const idx = this.openIndexes.indexOf(index);
      if (idx !== -1) {
        this.openIndexes.splice(idx, 1)
      } else {
        this.openIndexes.push(index)
      }
    },
    isOpen(index) {
      return this.openIndexes.indexOf(index) !== -1
    },
    isPartiallyOpen(index) {
      return this.partialOpenIndexes.indexOf(index) !== -1
    },
    partialContent(content) {
      let nextSpaceIndex = content.indexOf(' ', 100)
      if (nextSpaceIndex === -1) {
        nextSpaceIndex = content.length
      }
      return content.substring(0, nextSpaceIndex) + '...'
    }
  },
  mounted() {
    this.localFaqs = this.faqs
    this.removeEmptyFaq()
    this.calculatePosition()
  }
}
</script>

<style>
.rotate-180 {
  transform: rotate(180deg);
}

.fixed-grid {
  display: grid;
  gap: 20px;
}

.has-2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.has-1-cols {
  grid-template-columns: 1fr;
}
</style>
