<template>
  <section v-if="audiencePages" class="has-background-dark has-text-white"
    style="padding-top: 10vh; padding-bottom: 10vh;">
    <div class="container">
      <h2 class="subtitle has-text-white">{{ $t('audiences-pages-heading') }}</h2>
      <div class="columns">
        <div v-for="a in splicedAudiencePages(0, 3)" :key="a.handle" class="column">
          <a :href="translatedSlug(a)">
            <div class="card">
              <figure class="image is-4by3">
                <img :src="getPictureAudince(a)" style="object-fit: cover;" />
              </figure>
              <div class="card-content">
                <h5 class="title is-5">{{ $at(a, 'label') }}</h5>
                <p>{{ $at(a, 'headline') }}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="columns is-multiline">
        <div v-for="a in splicedAudiencePages(3, 99)" :key="a.handle" class="column is-one-fifth full-height"
          style="height: 5rem;">
          <a :href="translatedSlug(a)">
            <div class="card full-height">
              <div class="card-image-container">
                <img class="card-image" :src="getPictureAudince(a)" style="object-fit: cover;" />
              </div>
              <div
                class="custom-card-content is-flex is-align-items-center is-justify-content-center is-flex-direction-column p-1">
                <h5 class="title is-5 has-text-white">{{ $at(a, 'label') }}</h5>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { store } from '../store'

export default {
  name: 'ArenaStack',
  data: function () {
    return {
      priority: ['family', 'employer', 'friends']
    }
  },
  computed: {
    audiences() {
      return store.audiences
    },
    audiencePages() {
      const audiencesArray = Object.entries(this.audiences).map(([key, value]) => {
        return {
          ...value,
          handle: key
        }
      })
      const sortedArray = audiencesArray.sort((a, b) => a.label[store.language].localeCompare(b.label[store.language]))

      if (this.priority) {
        const priorityArray = sortedArray.filter(audience => this.priority.includes(audience.handle))
        const alphaArray = sortedArray.filter(audience => !this.priority.includes(audience.handle))
        return [...priorityArray, ...alphaArray]
      }

      return sortedArray
    },
  },
  methods: {
    splicedAudiencePages(start, end) {
      return [...this.audiencePages].splice(start, end)
    },
    getPictureAudince(audience) {
      let img = '/apuz/venue/static/images/' + audience.landingBlocks.capacity.coverPicture + '?w=480'
      return img
    },
    translatedSlug(audience) {
      let translated_slug = this.$at(audience.slug, [], audience.slug)
      return translated_slug
    }
  }
}
</script>
<style scoped>
.full-height {
  height: 100%;
}

.card-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-card-content {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.card-image {
  object-fit: cover;
  height: 100%;
  border-radius: 10px; 
  width: 100%;
}

.card {
  border-radius: 10px;
}

</style>