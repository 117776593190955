<template>
  <div class="container p-5">
    <article class="media">
      <figure class="media-left">
        <p class="image is-64x64 is-hidden-desktop">
          <img class="is-rounded img-cover" :src="'/apuz/avatar/' + $at(advice, 'author') + '.jpg'"/>
        </p>
        <p class="image is-128x128 is-hidden-touch">
          <img class="is-rounded img-cover" :src="'/apuz/avatar/' + $at(advice, 'author') + '.jpg'"/>
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <h4 class="title has-text-weight-bold m-0 responsive-title">“{{ $at(advice, 'heading') }}”</h4>
          <p class="subtitle is-size-7 is-hidden-desktop">{{ $at(advice, 'text') }}</p>
          <p class="subtitle is-size-4 is-hidden-touch">{{ $at(advice, 'text') }}</p>
        </div>
        <div class="subtitle is-size-7 is-size-4-desktop">{{ $at(advice, 'authorname') }}</div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'AdviceBlock',
  props: { advice: Object },
  data() {
    return {}
  },
  computed: {
  },
  methods: {

  }
}
</script>

<style scoped>
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.responsive-title {
  font-size: clamp(0.9rem, 2.5vw, 10rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .content {
    padding-right: 0rem;
  }
}
</style>
