<template>
  <div class="container mt-5 p-2">
    <div class="panel">
      <div class="panel-heading has-background-primary has-text-primary-invert">{{ $t('price-example-per-participant-text', { price: lowestPersonPrice }) }}</div>
      <div class="panel-block p-0 has-background">
        <div class="columns has-fullwidth m-0">
          <div class="column" :class="{ 'is-half': isDesktop }">
            <div>
              <div class="priceContainer" v-for="(group, index) in groups" :key="index">
                <div class="mt-5 mb-3">
                  <p class="title is-5 is-pulled-right has-text">
                    {{ $t('price-example-price', { price: group.bestOffer.price }) }}
                  </p>
                  <p class="title is-5 has-text">{{ $at(group, 'label') }}</p>
                </div>
                <p v-if="group.bestOffer.arenas">
                  {{ $t('price-example-text', {
                    participants: playerSummary(group.participants),
                    arenas: totalArenas(group.bestOffer.arenas),
                    date: dateToTranslatedText(group.bestOffer.date)
                  }) }}
                </p>
              </div>
            </div>
            <div class="priceContainer is-flex is-justify-content-center is-align-items-center" style="height: 100px;">
              <router-link :to="bookingLink()" class="button is-success btn-search">{{ $t('price-example-button')}}</router-link>
            </div>
          </div>
          <div class="column p-0 image-column is-hidden-touch" :class="{ 'is-half': isDesktop }">
            <div class="image-wrapper">
              <img :src="'/apuz/venue/static/images/' + image + '?w=480'" class="imageFullsize" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from '../../store.js'

export default {
  name: 'PriceExamplesBlock',
  props: {
    audience: Object
  },
  computed: {
    groups() {
      if (!this.audience['group-templates'] || !store.venue || !store.venue.bestOffers) {
        return []
      }


      return this.audience['group-templates'].filter(t => t.bestOffer && t.bestOffer.arenas).slice(0, 3)
    },
    image() {
      return this.audience.landingBlocks.priceExamples.illustration
    },
    language: function () {
      return store.language
    },
    lowestPersonPrice() {
      const pricesPerPerson = Object.values(this.groups).map(group => {
        const totalParticipants = Object.values(group.participants).reduce((sum, count) => sum + count, 0)
        return group.bestOffer.price / totalParticipants
      });

      const cheapestPricePerPerson = Math.min(...pricesPerPerson)
      
      return Math.ceil(cheapestPricePerPerson / 5) * 5
    }
  },
  methods: {
    computeVisibility(audience) {
      const hasBestOffer = audience["group-templates"].some(t => t.bestOffer && t.bestOffer.price)
      return hasBestOffer && audience["group-templates"].length > 0;
    },
    totalArenas(arenas) {
      return arenas.map((arena, index) => {
        const separator = index === 0 ? '' : index === arenas.length - 1 ? ` ${this.$t('and')} ` : ', '
        return separator + this.arenaName(arena)
      }).join('')
    },
    arenaName(comboHandle) {
      if (store.arenas[comboHandle] && store.arenas[comboHandle]['arena_name']) {
        return this.$at(store.arenas[comboHandle], 'marketing.brandNames', store.arenas[comboHandle]['arena_name'])
      }
      else {
        return ""
      }
    }
  }
}
</script>
<style scoped>
.container, .panel, .panel-block {
  width: 100%;
}

.columns {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 12px;
}

.imageFullsize {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
