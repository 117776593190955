<template>
  <div class="has-background" :class="classes" :style="[hasBackground, crossfade]" :data-theme="theme">
    <slot name="content-block" :backgroundStyle="hasBackground"></slot>
    <TextBlock v-if="hasText() && isInCorrectPosition('under-content')" :filename="audience.landingBlocks[blockType].textblock"></TextBlock>
    <FAQBlock v-if="hasFAQ()" :faqs="audience.landingBlocks[blockType].faq" class="pt-5"></FAQBlock>
    <TextBlock v-if="hasText() && isInCorrectPosition('under-faq')" :filename="audience.landingBlocks[blockType].textblock"></TextBlock>
    <AdviceBlock v-if="hasAdvice()" :advice="audience.landingBlocks[blockType].advice" class="pt-5"></AdviceBlock>
    <TextBlock v-if="hasText() && isInCorrectPosition('under-advice')" :filename="audience.landingBlocks[blockType].textblock"></TextBlock>
  </div>
</template>

<script>
import { store } from '../../store';
import AdviceBlock from './AdviceBlock.vue'
import FAQBlock from './FAQBlock.vue'
import TextBlock from './TextBlock.vue'

export default {
  components: {
    FAQBlock,
    AdviceBlock,
    TextBlock
  },
  props: {
    blockType: {
      type: String,
      required: true,
    },
    audience: {
      type: Object,
      required: true,
    },
    blockComponent: {
      type: Object,
      required: true
    }
  },
  computed: {
    blockobj () {
      return this.audience.landingBlocks[this.blockType]
    },
    classes () {
      let classes = {
        'content-block': this.shouldHavePadding,
      }
      if (this.hasBackground.class) {
        classes[this.hasBackground.class] = true
      }
      return classes
    },
    shouldHavePadding(){
    return !(this.blockType === "arenaSlides" || this.blockType === "capacity");    
    },
    hasBackground(){
      const background = this.blockobj && this.blockobj.background

      if(background){
        if(this.isImageUrl(background)){
          const url = '/apuz/venue/static/images/' + background
          return { 'backgroundImage': `url(${url})` }
        } 
        else {
          return { 'backgroundColor': background }
        }
      }

      if(this.blockComponent.methods.defaultBackground){
        return this.blockComponent.methods.defaultBackground()
      }
      return {}
    },
    theme(){
      let mode = false
      
      if (this.blockobj && this.blockobj.darkMode) {
        mode = this.blockobj.darkMode
      }
      else if (this.blockComponent.methods.defaultTheme) {
        mode = this.blockComponent.methods.defaultTheme()
      }

      return mode ? "dark" : "light"
    },
    crossfade(){
      const darkMode = this.audience.landingBlocks[this.blockType] && this.audience.landingBlocks[this.blockType].crossfade
      if (darkMode) return { 'boxShadow': '0 0 100px inset black'}
      return {}
    }
  },
  methods: {
    hasAdvice() {
      if (this.audience.landingBlocks[this.blockType] && this.audience.landingBlocks[this.blockType].advice) {
        const advice = this.audience.landingBlocks[this.blockType].advice
        const currentLanguage = store.language
        if (advice.text && advice.text[currentLanguage] &&
            advice.heading && advice.heading[currentLanguage] &&
            advice.author && advice.author[currentLanguage] &&
            advice.authorname && advice.authorname[currentLanguage]) {
          return true
        }
      }
      return false
    },
    hasFAQ() {
      const faq = this.audience.landingBlocks[this.blockType] && this.audience.landingBlocks[this.blockType].faq;
      return faq && faq.length > 0;
    },
    hasText() {
      const textblock = this.audience.landingBlocks[this.blockType] && this.audience.landingBlocks[this.blockType].textblock;
      return textblock && textblock != null
    },
    isImageUrl(background) {
      const imagePattern = /\.(jpg|jpeg|png|webp|gif)$/i;
      return imagePattern.test(background);
    },
    isInCorrectPosition(position) {
      if (!store || !store.textblocks || !store.textblocks[store.language]) {
        return false;
      }
      const file = this.audience.landingBlocks[this.blockType]?.textblock[store.language];
      if (!file || !store.textblocks[store.language][file]) {
        return false;
      }

      return store.textblocks[store.language][file].order == position;
    }
  },
}
</script>
<style scoped>
.content-block {
  padding: 0;
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  background-repeat: no-repeat;
  background-size: cover;
  }
</style>
